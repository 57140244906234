<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
        <v-col cols="12" lg="8">
            <BaseCard heading="Wallet master">
                <v-row>
                    <v-col cols="12" lg="4">
                        <div class="mt-0">
                            <v-text-field
                                v-model="wallet"
                                label="Wallet"
                                name="wallet"
                                background-color="transparent"
                            ></v-text-field>
                            
                        </div>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <div class="mt-0">
                            <v-text-field
                                v-model="descripcion"
                                label="Descripción"
                                name="descripcion"
                                background-color="transparent"
                            ></v-text-field>                            
                        </div>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <div class="mt-0">
                            <v-select
                            v-model="selTpWallets"
                            :items="tpWallets"
                            return-object
                            item-text="nombre"
                            item-value="id"
                            name="tpWallets"
                            label="Tipo de Wallet"
                            ></v-select>                            
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="12" style="text-align:center">
                        <div>
                            <v-alert
                            text
                            dense
                            type="success"
                            border="left"
                            class="py-5"
                            v-if="viewSuccess"
                            ><b>!Atención!</b> {{msgSuccess}}.</v-alert>
                            <v-alert
                                text
                                dense
                                type="error"
                                border="left"
                                class="py-5"
                                v-if="viewError"
                            ><b>!Atención!</b> {{msgError}}.</v-alert>
                        </div>
                        <v-btn 
                        class="mr-4" 
                        color="success"
                        :disabled="loading"
                        @click="saveWallet({id})"
                        data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i>"
                        >
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading"></span>
                            Guardar 
                        </v-btn>
                        <v-btn 
                        class="mr-4" 
                        color="warning"
                        :disabled="loading"
                        @click="volverList"
                        data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i>"
                        >
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading"></span>
                            Volver 
                        </v-btn>
                    </v-col>
                </v-row>
            </BaseCard>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import VsToast from '@vuesimple/vs-toast'

export default {
  name: "Wallermaster",

  data: () => ({
    id: 'undefined',
    usrToken: '',
    viewSuccess: false,
    msgSuccess: '',
    viewError: false,
    msgError: '',
    wallet: '',
    descripcion: '',
    titleConfig: 'Wallet master',
    loading: false,
    tpWallets: [],
    selTpWallets: []
  }),
  components: {},
  computed: {

    },

    watch: {
    },

    async created() {
        this.usrToken = localStorage.getItem("usrToken");
        this.id=this.$route.params.id;
        this.getTipowalletsmaster();
        if(typeof(this.id)!=='undefined'){
            this.getWallet(this.id);
        }
    },

    methods: {
        volverList(){
            this.$router.push('/pages/administracion/walletsmaster'); 
        },
        async saveWallet(id) {
            var url=`${process.env.VUE_APP_API_URL}/api/walletmaster`;
            var method = 'POST';
            if(typeof(id.id)!=='undefined'){
                console.log("Id: indefinido");
                url = url+'/'+id.id;
                method = 'PUT';
            }
            this.loading= true
            this.viewError= false
            this.viewSuccess= false
            await axios({
                method: method,
                url: url,
                params: {    
                    wallet: this.wallet,
                    tx_descripcion: this.descripcion,
                    tipowalletmaster_id: this.selTpWallets.id,                   
                },
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let resp = response.data
                console.log('respConfig...', resp);
                if (!resp.success){
                    VsToast.show({
                        title: this.titleConfig,
                        message: 'No se han podido guardar los datos de wallet.',
                        variant: 'error',
                        timeout: 2000,
                        type: "alert"
                    });
                    this.msgError = resp.data.msg
                    this.viewError = true
                }
                else {
                    console.log('respConfig Succes: ', resp)
                    VsToast.show({
                        title: this.titleConfig,
                        message: 'Wallet guardada exitosamente.',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                }
                this.viewSuccess = true
                this.msgSuccess = resp.msg
                setTimeout(() => (this.loading = false), 3000)
                this.$router.push('/pages/administracion/walletsmaster'); 
            })
            .catch(error => {
                VsToast.show({
                    title: this.titleConfig,
                    message: 'Error al guardar los datos de wallet.',
                    variant: 'error',
                    timeout: 2000,
                    type: "alert"
                });
                console.error('respConfig Error: ', error)
                this.msgError = (error.response && error.response.data) ? error.response.data.msg : error.message
                this.viewError = true
                setTimeout(() => (this.loading = false), 3000)
                
            })
        },

        async getWallet(id) {
            await axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/walletmaster/${id}`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respWallet = response.data
                console.log('respWallet...', respWallet);
                if (!respWallet.success){
                    VsToast.show({
                        title: this.titleConfig,
                        message: 'No se han podido cargar los datos de configuración.',
                        variant: 'error',
                        timeout: 2000,
                        type: "alert"
                    });
                }
                else {
                    console.log('respWallet Succes: ', respWallet)
                    this.wallet= respWallet.data.wallet
                    this.selTpWallets = respWallet.data.tipo_wallet_master
                }
            })
            .catch(error => {
                VsToast.show({
                    title: this.titleConfig,
                    message: 'Error al, cargar los datos de wallet.',
                    variant: 'error',
                    timeout: 2000,
                    type: "alert"
                });
                console.error('respConfig Error: ', error)
            })
        },

        async getTipowalletsmaster() {
            this.loading = true
            //Obtener los datos de las respTipowalletsusers
            await axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/tipowalletmaster`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken ,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                let respTipowalletsusers = response.data
                //console.log('Transacciones...', respTransaccion);
                if (!respTipowalletsusers.success){
                    console.error('Tipo wallet Error: ', respTipowalletsusers)
                }
                else {
                    this.tpWallets = respTipowalletsusers.data.map((data) => {
                        //console.log(trans.valor, trans.valor.toLocaleString("es-VE", {style:"currency", currency:"USD"}))
                        return {
                            id: data.id,
                            codigo: data.co_wallet,
                            nombre: data.nb_tipo_wallet,
                            logo: data.tx_img_logo
                        }
                    })
                    console.log('tpWallets:', this.tpWallets);
                }
                
                this.loading = false
            })
            .catch(error => {
                console.error('Tipo wallets Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
            })
        },
        cleanAlert() {
            this.viewError = false
            this.viewSuccess = false
        }
    },
};
</script>

<style>

.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
}

.btn-primary, .btn-info, .btn-default, .btn-warning, .btn-success, .btn-danger, .btn-outline-primary:hover, .btn-check:checked+.btn-outline-primary, .btn-check:checked+.btn-outline-warning, .btn-check:checked+.btn-outline-success, .btn-check:checked+.btn-outline-danger, .btn-outline-warning:hover, .btn-outline-danger:hover, .btn-outline-info:hover, .btn-outline-success:hover, .btn-primary.active, .btn-info.active, .btn-info:focus, .btn-info:disabled, .btn-success:disabled, .btn-primary:disabled, .btn-danger.active, .btn-success.active, .btn-success:focus, .btn-danger:focus, .btn-primary:focus, .show>.btn-danger.dropdown-toggle, .show>.btn-primary.dropdown-toggle, .show>.btn-info.dropdown-toggle, .show>.btn-success.dropdown-toggle, .show>.btn-warning.dropdown-toggle {
    color: #fff;
}

.btn-success:hover{
    background-color: #e8fdeb;
    border-color: rgba(6,215,156, 1);
    color:rgba(6,215,156, 1)!important
}

.saldo{
    background-color: #e8fdeb;
    border-color: #e8fdeb;
    color:rgba(6,215,156, 1)!important

}
</style>